$(window).on("load", function () {
  fadeOutLoadingPage();
  //animate
  new WOW({
    boxClass: "wow", // default
    animateClass: "animated", // default
    offset: 100, // default
    mobile: true, // default
    live: true, // default
  }).init();
});
$(function () {
  $(".nav-menu a").on("click", function (e) {
    e.preventDefault();
    slideToSection(this);
    $("#offcanvasNavbar .btn-close").trigger("click");
  });
  $(".magnifier-title").on("click", function (e) {
    let imgSrc = $(this).data("img");
    let title = $(this).text();
    // console.log(title);
    toggleModalImg(title,imgSrc);
  });
});

function slideToSection(el) {
  let target = $(el).attr("href");
  $("html,body").animate(
    {
      scrollTop:
        $(target).offset().top -
        ($(document).outerHeight() -
          ($(".content-main").outerHeight() + $("#footer").outerHeight())),
    },
    0
  );
}

function toggleModalImg(title,imgSrc) {
  $("#magnifierModal").find("img").attr("src", imgSrc);
  $("#magnifierModal").find(".modal-title").text(title);
}

function fadeOutLoadingPage() {
  $("#loading-page").fadeOut(800);
  $("body").addClass("onload");
}

// swiper
const swiper = new Swiper(".orientation-swiper", {
  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
